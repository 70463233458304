import React from "react";
import { withNavBars } from "../../HOCs";
import { ShippingSchema } from "./shippingSchema";

class ShippingSchemaParent extends React.Component {
  render() {
    return <ShippingSchema />;
  }
}
export default withNavBars(ShippingSchemaParent);
