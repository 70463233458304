import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Paper, Box } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ListItemText from "@mui/material/ListItemText";
import { List, Typography, Collapse } from "@mui/material";
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import MarginOutlinedIcon from "@mui/icons-material/MarginOutlined";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import { useHistory, useLocation } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import { Routes } from "../../../router/routes";
import { LocalStorageKeys } from "../../../utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import DiamondIcon from '@mui/icons-material/Diamond';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "0px 20px 20px 0px",
    // position: "relative",
    zIndex: 10000,
  },
  drawer: {
    width: "100%",
    position: "relative",
    height: "100vh",
    overflow: "hidden",
    boxShadow: "0px 5px 20px #0000000A",
    border: "1px solid #dcdcdc63",
  },
  drawerContainer: {
    overflow: "hidden",
  },
  title: {
    fontWeight: 600,
    margin: "20px 10px 0px",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
  },
  listView: {
    // overflow: "hidden",
    "& .MuiTypography-root": {
      marginRight: "10px",
    },
    "& .MuiListItem-root": {
      display: "-webkit-box",
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      padding: 4,
      width: "90%",
      marginTop: 10,
      "&:hover": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        padding: 4,
        width: "90%",
      },
    },
    "& .MuiSvgIcon-root": {
      display: "flex",
    },
    "& .MuiListItem-root.Mui-selected": {
      backgroundColor: theme.palette.primary.light,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
      color: "#8863FB",
      fontWeight: 600,

      "& .MuiTypography-root": {
        fontWeight: 500,
        fontSize: "16px",
      },
    },
  },
  listViewDataChild: {
    background: theme.palette.primary.light,
    borderRadius: "6px 8px 8px 6px",
    padding: 10,
    fontSize: 13,
    color: "#8863FB",
    cursor: "pointer",
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    margin: "18px 40px",
    fontWeight: 500,
  },
  listViewChild: {
    fontSize: 14,
    color: "#313147",
    cursor: "pointer",
    margin: "18px 40px",
    fontWeight: 500,
  },
  listViewChildParent: {
    borderLeft: "1.5px solid #00000029",
    marginLeft: "24px",
  },
  titles: {
    // fontWeight: 600,
    // fontSize: 20,
    // padding: "20px 0px",
    marginTop: 6,
    display: "flex",
    justifyContent: "center",
  },
  logout: {
    borderTop: "1px solid #00000012",
    position: "absolute",
    paddingTop: 16,
    bottom: 0,
    width: "100%",
    padding: "6px 16px",
    height: "56px",
    "& .log": {
      display: "flex",
      alignItems: "center",
    },
  },
  version: {
    position: "absolute",
    // bottom: 60,
    width: "80%",
    textAlign: "right",
  },
}));

const SideNavbarMenu = [
  {
    link: "/listing",
    tittle: "Manage Vendors",
    id: "6ccbba72-b4c3-4db9-a75c-5bf6ca28920b",
    showMenu: true,
    icon: <ManageAccountsIcon style={{ opacity: 0.7 }} />,
    IndexMenu: 1,
  },
  {
    tittle: "Manage Rules",
    icon: <ListAltIcon style={{ opacity: 0.7 }} />,
    showMenu: true,
    IndexMenu: 2,
    child: [
      {
        link: "/addrule",
        tittle: "Vendor Rules",
        id: "7189fcc5-54cf-4538-adc2-73f3e1567524",
        showMenu: true,
        icon: <DeviceHubOutlinedIcon style={{ opacity: 0.7, fontSize: 16 }} />,
        IndexMenuChild: 1,
      },
      {
        link: "/renameaddrule",
        tittle: "Rename Rules",
        id: "7189fcc5-54cf-4538-adc2-73f3e1567524",
        showMenu: true,
        icon: (
          <DriveFileRenameOutlineOutlinedIcon
            style={{ opacity: 0.7, fontSize: 16 }}
          />
        ),
        IndexMenuChild: 2,
      },
      {
        link: "/marginrule",
        tittle: "Margin Rules",
        id: "24e02040-8df2-47cc-ae7d-0a0a1defeea7",
        showMenu: true,
        icon: <MarginOutlinedIcon style={{ opacity: 0.7, fontSize: 16 }} />,
        IndexMenuChild: 3,
      },
    ],
  },
  {
    link: "/exchange",
    tittle: "Exchange",
    id: "4865a029-71e2-410b-b8ea-da64af9e7544",
    showMenu: true,
    icon: <EuroSymbolIcon style={{ opacity: 0.7 }} />,
    IndexMenu: 3,
  },
  {
    link: "/usermanagement",
    tittle: "User Management",
    id: "6645c2ab-da07-4bc6-a320-5c5a93adb024",
    showMenu: true,
    icon: <PermIdentityIcon style={{ opacity: 0.7 }} />,
    IndexMenu: 4,
  },
  {
    link: "/schedule",
    tittle: "Schedule",
    id: "8f55b558-932b-42e6-9ee0-3cd0004d95f8",
    showMenu: true,
    icon: <AccessTimeIcon style={{ opacity: 0.7 }} />,
    IndexMenu: 5,
  },
  {
    link: "/error-logs",
    tittle: "Error Logs",
    id: "e18c551e-4052-47c4-b18e-073207e7dd45",
    showMenu: true,
    icon: <ErrorOutlineIcon style={{ opacity: 0.7 }} />,
    IndexMenu: 6,
  },
  {
    link: "/paring",
    tittle: "Paring Logic",
    id: "e18c551e-4052-47c4-b18e-073207e7dd45",
    showMenu: true,
    icon: <ConnectWithoutContactIcon style={{ opacity: 0.7 }} />,
    IndexMenu: 7,
  },
  {
    link: "/diamond",
    tittle: "Diamond",
    id: "e18c551e-4052-47c4-b18e-073207e7dd45",
    showMenu: true,
    icon: <DiamondIcon style={{ opacity: 0.7 }} />,
    IndexMenu: 8,
  },
  
  // {
  //   tittle: "Logout",
  //   icon: <Logout style={{ opacity: 0.7 }} />,
  // },
];

export const SideNavBar = (props) => {
  const classes = useStyles(props);
  const location = useLocation();

  const history = useHistory();
  const [isActive, setActive] = React.useState(0);
  const [isActiveChild, setisActiveChild] = React.useState(0);

  const [navOptions, setNavOptions] = React.useState([]);

  let arr = JSON.parse(localStorage.getItem("roles"));

  useEffect(() => {
    SideNavbarMenu.map((x) => {
      if (x.id) {
        if (arr.indexOf(x.id) != -1) {
          x.showMenu = true;
        } else {
          x.showMenu = false;
        }
      } else {
        x?.child?.map((z) => {
          if (arr.indexOf(z.id) != -1) {
            z.showMenu = true;
          } else {
            z.showMenu = false;
          }
        });
      }
    });
    setNavOptions(SideNavbarMenu);
  }, []);

  React.useEffect(() => {
    setActive(
      (location?.state?.IndexMenu >= 0 && location?.state?.IndexMenu) ||
        (isActive >= 0 && isActive)
    );
    setisActiveChild(
      (location?.state?.IndexMenuChild >= 0 &&
        location?.state?.IndexMenuChild) ||
        (isActiveChild >= 0 && isActiveChild)
    );
  }, [location?.state]);

  const onListClick = (data, p, c) => {
    if (data?.link) {
      if (c >= 0) {
        history.push({
          state: {
            IndexMenu: p >= 0 && p,
            IndexMenuChild: c >= 0 && c,
          },
          pathname: data.link,
        });
      } else {
        history.push({
          state: {
            IndexMenu: p >= 0 && p,
          },
          pathname: data.link,
        });
      }
    } else {
      localStorage.removeItem(LocalStorageKeys.authToken);
      history.push(Routes.login);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.drawer} square>
        <div className={classes.drawerContainer}>
          <div className={classes.titles}>
            <img
              src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRL9jCDzgzw-_i6gNZbKutHVKf4HEQywfVnutdwm1z7yWjVvDML"
              alt="logo"
              width="70%"
              height="70%"
              style={{ margin: "auto" }}
            />
          </div>
          <br />
          <div className={classes.listView}>
            <List style={{ padding: 0 }}>
              {navOptions.map(
                (navBar, index) =>
                  navBar?.showMenu && (
                    <>
                      <ListItem
                        className={classes.menulist}
                        onClick={(e) => {
                          setActive(navBar?.IndexMenu);
                          !navBar?.child?.length > 0 &&
                            onListClick(navBar, navBar?.IndexMenu);
                          setisActiveChild(null);
                        }}
                        button
                        key={navBar.IndexMenu}
                        selected={isActive === navBar?.IndexMenu}
                      >
                        <Box style={{ display: "flex" }}>
                          {/* icons */}
                          &nbsp;&nbsp; <ListItemText primary={navBar?.icon} />
                          {/* title */}
                          <ListItemText primary={navBar.tittle} />
                          {navBar?.child?.length > 0 && (
                            <div>
                              {navBar?.IndexMenu === isActive ? (
                                <ExpandLessIcon style={{ marginTop: 3 }} />
                              ) : (
                                <ExpandMoreIcon style={{ marginTop: 3 }} />
                              )}
                            </div>
                          )}
                        </Box>
                        {/* end */}
                      </ListItem>
                      <Collapse
                        in={navBar?.IndexMenu === isActive}
                        timeout="auto"
                        unmountOnExit
                      >
                        {navBar?.child?.map((childData, childIndex) => {
                          if (childData?.showMenu) {
                            return (
                              <List
                                component="div"
                                disablePadding
                                key={childData.IndexMenuChild}
                                onClick={() => {
                                  setisActiveChild(childData.IndexMenuChild);
                                  onListClick(
                                    childData,
                                    navBar?.IndexMenu,
                                    childData.IndexMenuChild
                                  );
                                }}
                              >
                                <Typography
                                  style={{ display: "flex" }}
                                  className={
                                    childData.IndexMenuChild ===
                                      isActiveChild &&
                                    navBar?.IndexMenu === isActive
                                      ? classes.listViewDataChild
                                      : classes.listViewChild
                                  }
                                >
                                  {childData?.icon}&nbsp;&nbsp;
                                  {childData.tittle}
                                </Typography>
                              </List>
                            );
                          }
                        })}
                      </Collapse>
                    </>
                  )
              )}
            </List>
          </div>
        </div>
        {/* <Typography className={classes.version} variant="caption" color="textSecondary">
              {"v" + localStorage.getItem("version") }
            </Typography> */}
        <div className={classes.logout}>
          <Typography
            className={"log"}
            onClick={(e) => {
              onListClick();
            }}
          >
            <Logout style={{ opacity: 0.7 }} />
            &nbsp; Logout{" "}
            <Typography
              className={classes.version}
              variant="caption"
              color="textSecondary"
            >
              {" "}
              {"v" + localStorage.getItem("version")}{" "}
            </Typography>
          </Typography>
        </div>
      </Paper>
    </div>
  );
};
