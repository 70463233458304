import {
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Snackbar
} from "@mui/material";
import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import { Add, CenterFocusStrong } from "@mui/icons-material";
import { TabelComponent } from "../../components";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { RolesTabelComponent } from "./Component/RolesTableComponent";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NetWorkCallMethods } from "../../utils";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: 15,
    height: "fit-content",
    margin: "auto 0px",
  },
  create: {
    background: theme.palette.button.primary,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.button.primary,
      color: theme.palette.common.white,
    },
  },
  search: {
    backgroundColor: theme.palette.common.white,
    // padding: 5,
    border: "0px !important",
    borderRadius: "12px !important",
    boxShadow: "0px 20px 25px #D9D9D97A",
  },
  noBorder: {
    border: "none",
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      display: "flex",
      fontWeight: 600,
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: "-10px",
    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
      marginTop: "-2px",
    },
  },
}));

const Tittles = [
  // { tittle: "S.No" },
  { tittle: "Screens", align: "left" },
  { tittle: "Permissions", align: "left" },
  // { tittle: "Editor" },
  // { tittle: "Developer" },
  // { tittle: "Viewer" },
];

const ManageRoleAccess = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [offset, setOffset] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const [state, setState] = React.useState({})

  const handleNewRoleList = () => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);
    NetworkCall(
      // `${config.api_url}features/get/all?limit=${limit}&offset=${offset}`,
      `${config.api_url}features/get/all`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        let temp_data = res?.data?.data?.rows;
        console.clear();
        console.log(res);
        // if(temp_data){
        setData(temp_data);
        setCount(res?.data?.data?.count);
        // }
        // else{

        // }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);
    NetworkCall(
      // `${config.api_url}features/get/all?limit=${limit}&offset=${offset}`,
      `${config.api_url}roles/get?role_id=${location?.state?.data?.id}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        let temp_data = res?.data?.data?.feature_ids;
        if (temp_data) {
          let temp_arr = [ ...temp_data, ...temp_data]
        // if(temp_data){
          console.clear();
        console.log(location?.state?.data?.role_name);
          setData(temp_data);
          setCount(res?.data?.data?.feature_ids?.length);
        } else {
          handleNewRoleList();
        }
      })
      .catch((err) => {});
  }, []);

  const updatePermission = (payload) => {

    setState(payload)

    
  }

  const upsertPermission = () => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);
    if(Object.keys(state).length > 0)
    {
    NetworkCall(
      `${config.api_url}roles/upsert`,
      NetWorkCallMethods.post,
      {
        ...state,
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        // history.goBack(-1)
        setAlert({
          open: true,
          message: `Permissions Updated`,
          backgroundColor: `#4BB543`,
        });
      })
      .catch((err) => {
        console.log(err.response?.data?.message)
        setAlert({
          open: true,
          message: `${err.response?.data?.message}`,
          backgroundColor: `#FF3232`,
        });
      });
    }
    else{
      setAlert({
        open: true,
        message: `No Changes Made yet`,
        backgroundColor: `#FF3232`,
      });
    }
  }

  return (
    <>
      <>
        <div className={classes.root}>
          <div className={classes.titleSection}
            style={{
              textAlign: "right",
              marginBottom: "25px",
              //   marginTop: "25px",
            }}
          >
            {/* <Stack flexDirection="row" justifyContent="space-between"> */}
               <span>
          <Typography variant="subtitle2">
            <ArrowBackIcon
              className="icon"
              onClick={() => history.push("/usermanagement", { data: "1", IndexMenu: 4,})}
            />
             {`${location?.state?.data?.role_name} Role Access (${count})`}
          </Typography>
        </span>
              <div>
                
                <Button
                  className={classes.create}
                  variant="contained"
                  onClick={() => upsertPermission()}
                >
                  Update Permission
                </Button>
              </div>
            {/* </Stack> */}
          </div>
        </div>
        <RolesTabelComponent
          data={data}
          loading={false}
          tittles={Tittles}
          setRowsPerPage={setLimit}
          setPage={setOffset}
          page={offset}
          rowsPerPage={limit}
          count={count}
          search={search}
          org_data={location.state?.data}
          ScheduleEditActive={(payload) => {
            // history.goBack(-1);
            updatePermission(payload)
          }}
        />
        <Snackbar
          open={alert.open}
          message={alert.message}
          ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAlert({ ...alert, open: false })}
          autoHideDuration={4000}
        />
      </>
    </>
  );
};

export default ManageRoleAccess;
