import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  OutlinedInput,
  Grid,
  Button,
  Chip,
  Snackbar,
  Switch,
} from "@mui/material";
import { TextBox } from "../../../components";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { styled } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "../style";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import moment from "moment";
import { withNavBars } from "../../../HOCs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MuiPaper from "@mui/material/Paper";
import { useHistory, useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { ValidateEmail } from "../../../utils/validations";
import { Schedule } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";

// Constant Data
const ScheduleType = [
  {
    label: "Vendor Master",
    value: "Vendor Master",
  },
  {
    label: "Vendor Specific",
    value: "Vendor Specific",
  },
];
// Styles
const StyledLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "12px",

  marginBottom: "0.35em",
  "& .css-u0qe18-MuiFormLabel-root.Mui-focused": {
    color: "#00000099 !important",
  },
}));

const Styledselect = styled(Select)(({ theme }) => ({
  borderRadius: "10px",
  "& .MuiOutlinedInput-input": {
    padding: "11px 14px !important",
  },
}));
const StyleChip = withStyles({
  root: {
    borderRadius: "4px",
    padding: "4px",
    margin: "0px 4px",
  },
})(Chip);
const Paper = styled(MuiPaper, {
  shouldForwardProp: (prop) => prop !== "moreGutter",
})(({ moreGutter }) => ({
  height: "100%",
  border: "1px solid #DCDCDC",
  borderRadius: 10,
  padding: "32px 24px",
  ...(moreGutter && {
    padding: "32px",
  }),
}));
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FF4D4A" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const EditSchedule = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const theme = useTheme();
  const params = useParams();

  // States
  const [allVendor, setAllVendor] = useState(null);
  const [allFilterRule, setAllFilterRule] = useState([]);
  const [allMarginRule, setAllMarginRule] = useState([]);
  const [allRenameRule, setAllRenameRule] = useState([]);
  const [marginRule, setMarginRule] = useState([]);
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });
  const [schedule, setSchedule] = React.useState({
    id: "",
    name: "",
    time: new Date(),
    type: "",
    vendor: "",
    filterRule: "",
    renameRule: "",
    active: true,
    email: "",
    error: {
      name: "",
      time: new Date(),
      type: "",
      vendor: "",
      filterRule: "",
      renameRule: "",
      email: "",
    },
  });

  //  Side Effects
  useEffect(() => {
    history.push({
      state: {
        IndexMenu: 5,
      },
    });
  }, []);
  useEffect(() => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);

    NetworkCall(
      `${config.api_url}schedule/get?vendor_id=${params.vendor_id}&id=${params.id}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        if (res?.data?.success) {
          const { data } = res.data;

          setSchedule({
            id: data?.id,
            name: data?.schedule_name ?? "",
            time: new Date(),
            type:
              data?.schedule_for === "Extract"
                ? "Vendor Specific"
                : "Vendor Master",
            vendor: data?.vendor_id ?? "",
            filterRule: data?.filter_rules_id ?? "",
            renameRule: data?.rename_rules_id ?? "",
            active: data?.is_active,
            email: data?.email_id[0] ?? "",
            error: {
              name: "",
              time: new Date(),
              type: "",
              vendor: "",
              filterRule: "",
              renameRule: "",
              email: "",
            },
          });
          setMarginRule(data.margin_rules_id ?? []);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    schedule.type === "Vendor Specific" && GetAllVendors();
  }, [schedule.type]);

  useEffect(() => {
    schedule?.vendor && GetVendorDetails();
  }, [schedule.vendor]);

  const handleChange = (name, val) => {
    let error = schedule.error;
    error[name] = "";
    setSchedule({ ...schedule, [name]: val, error });
  };

  const handleUpdateArray = (name, val) => {
    setMarginRule(val);
  };
  const handleDelete = (e, deleteId) => {
    setMarginRule(marginRule.filter((value) => value !== deleteId));
  };
  const handleValidate = () => {
    let isValid = true;
    let error = schedule.error;

    if (schedule.name.length === 0) {
      isValid = false;
      error.name = "Name is Required";
    }
    if (schedule.time.length === 0) {
      isValid = false;
      error.time = "Time is Required";
    }
    if (schedule.type.length === 0) {
      isValid = false;
      error.type = "Type is Required";
    }

    if (schedule?.type === "Vendor Master") {
      if (schedule.email.length === 0) {
        isValid = false;
        error.email = "Email is Required";
      }
      if (!ValidateEmail(schedule.email)) {
        isValid = false;
        error.email = "Enter Valid Email";
      }
    }
    if (schedule.type === "Vendor Master") {
      setSchedule({ ...schedule, error });
      return isValid;
    }
    if (schedule.vendor.length === 0) {
      isValid = false;
      error.vendor = "Vendor is Required";
    }
    if (schedule.filterRule.length === 0) {
      isValid = false;
      error.filterRule = "Filter Rule is Required";
    }
    if (schedule.renameRule.length === 0) {
      isValid = false;
      error.renameRule = "Rename Rule is Required";
    }
    if (marginRule.length === 0) {
      isValid = false;
      error.marginRule = "Margin Rule is Required";
    }

    setSchedule({ ...schedule, error });
    return isValid;
  };

  // Fetch Functions
  const GetAllVendors = async () => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);
    await NetworkCall(
      `${config.api_url}vendors/get/all`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        setAllVendor(res?.data?.data?.rows ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const GetVendorDetails = async () => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);

    await NetworkCall(
      `${config.api_url}rules/get/all?vendor_id=${schedule?.vendor}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        setAllFilterRule(res?.data?.filter_rules ?? []);
        setAllMarginRule(res?.data?.margin_rules ?? []);
        setAllRenameRule(res?.data?.rename_rules ?? []);
        // setMarginRule([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async () => {
    if (handleValidate()) {
      let token = localStorage.getItem("auth_token")?.toString();
      token = token?.substring(1, token.length - 1);
      let Payload = {};
      if (schedule.type === "Vendor Master") {
        Payload = {
          id: schedule.id,
          schedule_name: schedule.name,
          schedule_for: "Fetch",
          schedule_times: moment(schedule.time).format("HH:mm"),
          time_zone: moment.tz.guess(),
          active: schedule.active,
          email_id: [schedule.email],
        };
      } else {
        Payload = {
          id: schedule.id,
          schedule_name: schedule.name,
          schedule_for: "Extract",
          schedule_times: moment(schedule.time).format("HH:mm"),
          time_zone: moment.tz.guess(),
          vendor_id: schedule.vendor,
          rename_rules_id: schedule.renameRule,
          filter_rules_id: schedule.filterRule,
          margin_rules_id: marginRule,
          active: schedule.active,
          email_id: [""],
        };
      }
      await NetworkCall(
        `${config.api_url}schedule/update`,
        "POST",

        Payload,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      )
        .then((res) => {
          if (res.data.success) {
            setAlert({
              open: true,
              message: res?.data?.message ?? "Updated Successfully!",
              backgroundColor: "#4BB543",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setAlert({
            open: true,
            message: "Something Went Wrong!",
            backgroundColor: "#FF3232",
          });
        });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <span>
          <Typography variant="subtitle2">
            <ArrowBackIcon
              className="icon"
              onClick={() => history.push("/schedule")}
            />
            {schedule?.name ?? ""}
          </Typography>
        </span>
      </div>
      <Grid
        container
        xs={12}
        columnGap={1.5}
        wrap="nowrap"
        className={classes.main}
      >
        <Grid item xs={12}>
          <Paper
            elevation={0}
            moreGutter
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Grid container xs={12} rowSpacing={4} columnSpacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextBox
                    label={"Schedule Name"}
                    type={"text"}
                    placeholder={"Schedule Name"}
                    value={schedule.name}
                    isRequired
                    name="name"
                    onChange={(e) => handleChange("name", e.target.value)}
                    isError={schedule?.error?.name?.length > 0}
                    errorMessage={schedule?.error?.name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <StyledLabel>
                    Schedule Type
                    <sup style={{ color: theme.palette.error.main }}>*</sup>
                  </StyledLabel>
                  <Styledselect
                    sx={{ padding: "0px !important" }}
                    value={schedule.type}
                    required={true}
                    placeholder="Schedule Type"
                    input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                    onChange={(e) => handleChange("type", e.target.value)}
                  >
                    {ScheduleType?.map((x) => {
                      return (
                        <MenuItem key={x?.value} value={x?.value}>
                          {x?.label}
                        </MenuItem>
                      );
                    })}
                  </Styledselect>
                  {schedule?.error?.type && (
                    <Typography variant={"caption"} color={"error"}>
                      {schedule?.error?.type}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <StyledLabel>
                    Schedule Time
                    <sup style={{ color: theme.palette.error.main }}>*</sup>
                  </StyledLabel>

                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    className={classes.localizationStyle}
                    size="small"
                  >
                    <TimePicker
                      sx={{
                        padding: "0px !important",
                        borderRadius: "8px !important",
                      }}
                      style={{ width: "100%" }}
                      className={classes.datepicker}
                      value={schedule.time}
                      onChange={(newTime) =>
                        setSchedule({ ...schedule, time: newTime })
                      }
                      renderInput={(params) => (
                        <TextField {...params} className={classes.textbox} />
                      )}
                      size="small"
                      isError={schedule?.error?.time?.length > 0}
                      errorMessage={schedule?.error?.time}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              {schedule?.type === "Vendor Master" && (
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <TextBox
                      label={"Email"}
                      type={"text"}
                      placeholder={"Email"}
                      value={schedule?.email ?? ""}
                      isRequired
                      name="email"
                      onChange={(e) => handleChange("email", e.target.value)}
                      isError={schedule?.error?.email?.length > 0}
                      errorMessage={schedule?.error?.email}
                    />
                  </FormControl>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                md={schedule?.type === "Vendor Master" ? 4 : 8}
              >
                <Grid container style={{ padding: "18px 0px 0px 0px" }}>
                  <FormControl>
                    <StyledLabel>
                      Schedule Status
                      {/* <sup style={{ color: theme.palette.error.main }}>*</sup> */}
                    </StyledLabel>
                    <Grid>
                      <span
                        style={{
                          fontSize: "12px",
                          color: schedule.active ? "#848484" : "#FF4D4A",
                        }}
                      >
                        Stop&nbsp;
                      </span>
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked
                        checked={schedule.active}
                        onChange={(e) =>
                          handleChange("active", e.target.checked)
                        }
                      />
                      <span
                        style={{
                          fontSize: "12px",
                          color: schedule.active ? "#3CD291" : "#848484",
                        }}
                      >
                        &nbsp;Start
                      </span>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container style={{ padding: "18px 0px 0px 0px" }}></Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  visibility:
                    schedule.type === "Vendor Specific" ? "unset" : "hidden",
                }}
              >
                <FormControl fullWidth>
                  <StyledLabel>
                    Choose Vendor
                    <sup style={{ color: theme.palette.error.main }}>*</sup>
                  </StyledLabel>
                  <Styledselect
                    sx={{ padding: "0px !important" }}
                    value={schedule.vendor ?? ""}
                    required={true}
                    defaultValue={schedule.vendor ?? ""}
                    placeholder="Filter Rule"
                    disabled={
                      schedule.type === "Vendor Specific" ? false : true
                    }
                    input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                    onChange={(e) => handleChange("vendor", e.target.value)}
                  >
                    {allVendor?.map((x) => {
                      return (
                        <MenuItem key={x?.id} value={x?.id}>
                          {x?.first_name ?? "No Name"}
                        </MenuItem>
                      );
                    })}
                  </Styledselect>
                  {schedule?.error?.vendor && (
                    <Typography variant={"caption"} color={"error"}>
                      {schedule?.error?.vendor}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  visibility:
                    schedule.type === "Vendor Specific" ? "unset" : "hidden",
                }}
              >
                <FormControl fullWidth>
                  <StyledLabel>
                    Choose Filter Rule
                    <sup style={{ color: theme.palette.error.main }}>*</sup>
                  </StyledLabel>
                  <Styledselect
                    sx={{ padding: "0px !important" }}
                    value={schedule.filterRule}
                    required={true}
                    placeholder="Filter Rule"
                    disabled={
                      schedule.type === "Vendor Specific" ? false : true
                    }
                    input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                    onChange={(e) => handleChange("filterRule", e.target.value)}
                  >
                    {allFilterRule &&
                      allFilterRule?.map((x) => {
                        return (
                          <MenuItem value={x?.id}>{x?.rule_name}</MenuItem>
                        );
                      })}
                    {allFilterRule === undefined ||
                      (allFilterRule.length == 0 && (
                        <MenuItem disabled>No Data</MenuItem>
                      ))}
                  </Styledselect>
                  {schedule?.error?.filterRule && (
                    <Typography variant={"caption"} color={"error"}>
                      {schedule?.error?.filterRule}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  visibility:
                    schedule.type === "Vendor Specific" ? "unset" : "hidden",
                }}
              >
                <FormControl fullWidth>
                  <StyledLabel>
                    Choose Rename Rule
                    <sup style={{ color: theme.palette.error.main }}>*</sup>
                  </StyledLabel>
                  <Styledselect
                    sx={{ padding: "0px !important" }}
                    value={schedule.renameRule}
                    required={true}
                    placeholder="Rename Rule"
                    disabled={
                      schedule.type === "Vendor Specific" ? false : true
                    }
                    input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                    onChange={(e) => handleChange("renameRule", e.target.value)}
                  >
                    {allRenameRule?.map((x) => {
                      return <MenuItem value={x?.id}>{x?.rule_name}</MenuItem>;
                    })}
                    {allRenameRule === undefined ||
                      (allRenameRule.length == 0 && (
                        <MenuItem disabled>No Data</MenuItem>
                      ))}
                  </Styledselect>
                  {schedule?.error?.renameRule && (
                    <Typography variant={"caption"} color={"error"}>
                      {schedule?.error?.renameRule}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                style={{
                  visibility:
                    schedule.type === "Vendor Specific" ? "unset" : "hidden",
                }}
              >
                <FormControl fullWidth>
                  <StyledLabel>
                    Choose Margin Rules
                    <sup style={{ color: theme.palette.error.main }}>*</sup>
                  </StyledLabel>
                  <Styledselect
                    sx={{ padding: "0px !important" }}
                    value={marginRule ?? []}
                    required={true}
                    placeholder="Margin Rule"
                    label="Margin Rule"
                    onChange={(e) =>
                      handleUpdateArray("marginRule", e.target.value)
                    }
                    disabled={
                      schedule.type === "Vendor Specific" ? false : true
                    }
                    multiple
                    input={<OutlinedInput id="select-multiple-chip" label="" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <StyleChip
                            key={value}
                            label={
                              allMarginRule?.find((val) => val?.id === value)
                                ?.rule_name ?? ""
                            }
                            className={classes.chipStyle}
                            style={{ borderRadius: "0px !important" }}
                            onDelete={(e) => handleDelete(e, value)}
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {allMarginRule?.map((x) => {
                      return (
                        <MenuItem key={x.id} value={x.id}>
                          {x.rule_name}
                        </MenuItem>
                      );
                    })}
                    {allMarginRule === undefined ||
                      (allMarginRule.length == 0 && (
                        <MenuItem disabled>No Data</MenuItem>
                      ))}
                  </Styledselect>
                  {schedule?.error?.marginRule && (
                    <Typography variant={"caption"} color={"error"}>
                      {schedule?.error?.marginRule}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <Grid
                container
                justifyContent="flex-end"
                style={{ padding: "18px 0px 0px 0px" }}
                columnSpacing={2}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      history.push({
                        pathname: "/schedule",
                        state: { IndexMenu: 5 },
                      })
                    }
                    style={{
                      padding: "16px 24px",
                      color: "#DE57E5",
                      background: " #fff",
                      border: "1px solid #848484 ",
                      borderRadius: "8px",
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleSubmit}
                    style={{
                      padding: "16px 24px",
                      color: "#fff",
                      background:
                        "transparent linear-gradient(90deg, #DE57E5 0%, #BB63FB 100%) 0% 0% no-repeat padding-box",
                      border: "1px solid #848484 ",
                      borderRadius: "8px",
                    }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={alert.open}
        message={alert.message}
        ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={4000}
      />
    </div>
  );
};

export default withNavBars(EditSchedule);
