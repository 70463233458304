import React, { useEffect, useState } from "react";
import {
    Button,
    Grid,
    IconButton,
    InputLabel,
    useTheme,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Typography,
    Avatar,
    OutlinedInput,
    FormControl,
    FormLabel,
    MenuItem,

    Stack,
} from "@mui/material";
import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { Styledselect } from "../../screens/addnewvendor/addvendor";
import UploaderWithPreview from "../uploaderWithPreview";


const StyledLabel = styled(FormLabel)(({ theme }) => ({
    fontSize: "12px",
    // color: "#231535",
    marginBottom: "0.35em",
    "& .css-u0qe18-MuiFormLabel-root.Mui-focused": {
        color: "#00000099 !important",
    },
}));

const BulkUpload = (props) => {

    const { vendorOptions, bulkUpload } = props
    const theme = useTheme();
    const [vendor, setVendor] = useState()
    const [uploadedFile, setUploadedFile] = useState();

    return (
        <div style={{ margin: "1rem 1.5rem 2rem" }}>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
            >
                <a href={window.location.href.indexOf("vfa-dev") > -1 ? "https://caratlane-staging.s3.ap-south-1.amazonaws.com/templates/margin_rules_bulk_insert_template.xlsx" : "https://caratlane-production.s3.ap-south-1.amazonaws.com/templates/margin_rules_bulk_insert_template.xlsx"} download>Download Demo File</a>
                {/* <Button style={{ maxWidth: '200px' }} variant="outlined">Download Demo File</Button> */}
            </Stack>
            <FormControl fullWidth style={{ paddingBottom: 3 }}>
                <StyledLabel>
                    Select Vendor
                    <sup style={{ color: theme.palette.error.main }}>*</sup>
                </StyledLabel>
                <Styledselect
                    sx={{ padding: "0px !important" }}
                    // labelId="demo-simple-select-label"
                    placeholder={`Network Call Methods`}
                    value={vendor}
                    required={true}
                    isRequired
                    label={`Network Call Methods`}
                    input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(e) => { setVendor(e.target.value) }}
                >
                    {vendorOptions?.map((x) => {
                        return <MenuItem value={x.id}>{x.name}</MenuItem>;
                    })}
                </Styledselect>
            </FormControl>
            <UploaderWithPreview
                handleFile={(e) => setUploadedFile(e)}
                handleRemove={() => { }}
                label={
                    <StyledLabel>
                        Upload CSV (or) Excel File{" "}
                        <sup style={{ color: theme.palette.error.main }}>
                            *
                        </sup>
                    </StyledLabel>
                }
                value={""}
                customExtensions={
                    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                }
                file={null}
                fileUrl={uploadedFile}
                // fileLink={uploadedFile}
                isEdit={props.isStepper ? true : false}
                // fileUrl={""}
                idData={""}
                parentID={"id"}
            />

            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                style={{ paddingTop: 10 }}
            >
                <Button variant="contained" onClick={() => bulkUpload(vendor, uploadedFile)}>Upload</Button>
            </Stack>
        </div>
    )
}

export default BulkUpload;