import makeStyles from "@mui/styles/makeStyles";

const ShippingSchemaStyles = makeStyles((theme) => ({
  titlesection: {
    display: "flex",
    alignItems: "center",
    margin: "20px 20px 14px 20px",
    // align-items: center;
    justifyContent: "left",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      fontWeight: 600,
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: 16,
    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
    },
  },

  rootBody: {
    margin: "20px 16px 0px",
  },

  addruleDrawer: {
    backgroundColor: "#fff",
    borderRadius: 6,
    zIndex: 1000,
  },

  addruleDrawerBody: {
    padding: 20,
    height: "60vh",
    overflow: "auto",
  },
  addruleDrawerBody1: {
    padding: 20,
    overflow: "auto",
  },
  footer: {
    borderTop: "1px solid #eeeeee",
    bottom: 96,
    right: 0,
    display: "flex",

    justifyContent: "flex-end",

    overflow: "hidden",
    padding: 10,
    background: "#fff",
    // position: "absolute",
    width: "100%",
    left: "24px",
    borderRadius:6,
    marginLeft: 16,
    marginTop: 2,
  },
}));

export { ShippingSchemaStyles };
