import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  OutlinedInput,
  Grid,
  Chip,
  Button,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/styles";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

import { TextBox, MobileNumberInputComponent } from "../../components";

const ScheduleType = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Individual",
    value: "Individual",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  borderRadius: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 5,
  //   py: 2
};

const Styledselect = styled(Select)(({ theme }) => ({
  borderRadius: "10px",
  "& .MuiOutlinedInput-input": {
    padding: "11px 14px !important",
  },
}));
const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
    "& sup": {
      color: theme.palette.error.main,
    },
  },
  datepicker: {
    width: "100%",
    border: "none",
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "8px",

      boxShadow: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 14px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CED3DD !important",
    },
  },
}));

const StyledLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "12px",
  // color: "#231535",
  marginBottom: "0.35em",
  "& .css-u0qe18-MuiFormLabel-root.Mui-focused": {
    color: "#00000099 !important",
  },
}));

const AddRoles = (props) => {
  const { open, handleClose, roles, isEdit } = props;
  const theme = useTheme();

  const [ role_name, setRoleName] = useState("")

  const handleChange = (name, val) => {}

  const handleUpdate = () => {
      let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);

    let payload = {
        role_name: role_name
    }

    if(isEdit){
        payload.role_id = roles?.id
    }
      
      NetworkCall(
        `${config.api_url}roles/upsert`,
        "POST",
        {
            ...payload
        },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      ).then((res) => {
        props?.updateMessage(roles?.id ? `Role Updated` : `Role Added`, `#4BB543`)
        handleClose()
      }).catch((err) => {
        props?.updateMessage(`Something went wrong`, `#FF3232`)
        handleClose()
      })
  }

  useEffect(() => {
      setRoleName(roles?.role_name)
    // console.log(roles)
  },[roles])


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography>{isEdit ? `Edit Role Name` : `Add a New Role`}</Typography>
        <FormControl fullWidth>
            <TextBox
                  label={"Role Name"}
                  type={"text"}
                  isRequired
                  placeholder={"Role Name"}
                  value={role_name}
                  onChange={(e) => setRoleName(e.target.value)}
                />
        </FormControl>
        <Stack
          direction="row"
          spacing={1}
          style={{ paddingTop: 20, display: "flex", justifyContent: "right" }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button sx={{ background: theme.palette.button.primary }} variant="contained" onClick={() => {handleUpdate()}}>{isEdit ? `Update` : `Add`}</Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddRoles;
