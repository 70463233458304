/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

export let NetworkMethodsOptions = [
  {
    label: "GET",
    value: "GET",
    key: "GET",
  },
  {
    label: "POST",
    value: "POST",
    key: "POST",
  },
  {
    label: "PUT",
    value: "PUT",
    key: "PUT",
  },
  {
    label: "DELETE",
    value: "DELETE",
    key: "DELETE",
  },
  {
    label: "UPDATE",
    value: "UPDATE",
    key: "UPDATE",
  },
];

export let currencyValue = [
  {
    label: "MYR",
    value: "MYR",
    key: "MYR",
  },
  {
    label: "USD",
    value: "USD",
    key: "USD",
  },
  {
    label: "EURO",
    value: "EURO",
    key: "EURO",
  },
  {
    label: "INR",
    value: "INR",
    key: "INR",
  },
];

export let genderValue = [
  {
    label: "Male",
    value: "Male",
    key: "Male",
  },
  {
    label: "Female",
    value: "Female",
    key: "Female",
  },
  {
    label: "Other",
    value: "Other",
    key: "Other",
  },
];

export let ExchangeMethodsOptions = [
  {
    label: "GET",
    value: "GET",
    key: "GET",
  },
  {
    label: "POST",
    value: "POST",
    key: "POST",
  },
  {
    label: "PUT",
    value: "PUT",
    key: "PUT",
  },
];

export let feedResponseAs = [
  {
    label: "JSON",
    value: "JSON",
    key: "JSON",
  },
  {
    label: "String",
    value: "String",
    key: "String",
  },
  {
    label: "XML",
    value: "XML",
    key: "XML",
  },
];

export let caratlaneSchema = [
  {
    id: "1",
    tittle: "ID",
    isEnabled: true,
  },
  {
    id: "2",
    tittle: "URL",
    isEnabled: true,
  },
  {
    id: "3",
    tittle: "ImagesURL",
    isEnabled: true,
  },
  {
    id: "4",
    tittle: "VideosURL",
    isEnabled: true,
  },
  {
    id: "5",
    tittle: "CertificateLab",
    isEnabled: true,
  },
  {
    id: "6",
    tittle: "CertificateURL",
    isEnabled: true,
  },
  {
    id: "7",
    tittle: "CertificateNumber",
    isEnabled: true,
  },
  {
    id: "8",
    tittle: "PricePerCarat",
    isEnabled: true,
  },
  {
    id: "9",
    tittle: "WirePrice",
    isEnabled: true,
  },
  {
    id: "10",
    tittle: "Shape",
    isEnabled: true,
  },
  {
    id: "11",
    tittle: "Carat",
    isEnabled: true,
  },
  {
    id: "12",
    tittle: "Cut",
    isEnabled: true,
  },
  {
    id: "13",
    tittle: "Color",
    isEnabled: true,
  },
  {
    id: "14",
    tittle: "Clarity",
    isEnabled: true,
  },
  {
    id: "15",
    tittle: "Fluorescence",
    isEnabled: true,
  },
  {
    id: "16",
    tittle: "Polish",
    isEnabled: true,
  },
  {
    id: "17",
    tittle: "Symmetry",
    isEnabled: true,
  },
  {
    id: "18",
    tittle: "TableWidth",
    isEnabled: true,
  },
  {
    id: "19",
    tittle: "TableWidthPercentage",
    isEnabled: true,
  },
  {
    id: "20",
    tittle: "GirdleThick",
    isEnabled: true,
  },
  {
    id: "21",
    tittle: "GirdleThin",
    isEnabled: true,
  },
  {
    id: "22",
    tittle: "GirdleDiameter",
    isEnabled: true,
  },
  {
    id: "23",
    tittle: "Culet",
    isEnabled: true,
  },
  {
    id: "24",
    tittle: "CuletSize",
    isEnabled: true,
  },
  {
    id: "25",
    tittle: "CuletAngle",
    isEnabled: true,
  },
  {
    id: "26",
    tittle: "CrownHeight",
    isEnabled: true,
  },
  {
    id: "27",
    tittle: "CrownHeightPercentage",
    isEnabled: true,
  },
  {
    id: "28",
    tittle: "CrownAngle",
    isEnabled: true,
  },
  {
    id: "29",
    tittle: "PavilionDepth",
    isEnabled: true,
  },
  {
    id: "30",
    tittle: "PavilionDepthPercentage",
    isEnabled: true,
  },
  {
    id: "31",
    tittle: "PavilionAngle",
    isEnabled: true,
  },
  {
    id: "32",
    tittle: "DepthPercentage",
    isEnabled: true,
  },
  {
    id: "33",
    tittle: "LengthToWidthRatio",
    isEnabled: true,
  },
  {
    id: "34",
    tittle: "Measurements",
    isEnabled: true,
  },
  {
    id: "35",
    tittle: "GirdleToTableDistance",
    isEnabled: true,
  },
  {
    id: "36",
    tittle: "StarLength",
    isEnabled: true,
  },
  {
    id: "37",
    tittle: "StarLengthPercentage",
    isEnabled: true,
  },
  {
    id: "38",
    tittle: "GirdleToCuletDistance",
    isEnabled: true,
  },
  {
    id: "39",
    tittle: "LowerHalfLength",
    isEnabled: true,
  },
  {
    id: "40",
    tittle: "LowerHalfLengthPercentage",
    isEnabled: true,
  },
  {
    id: "41",
    tittle: "ShippingDays",
    isEnabled: true,
  },
  {
    id: "42",
    tittle: "LabGrown",
    isEnabled: true,
  },
  {
    id: "43",
    tittle: "Availability",
    isEnabled: true,
  },
  {
    id: "44",
    tittle: "Country",
    isEnabled: true,
  },
  {
    id: "45",
    tittle: "State",
    isEnabled: true,
  },
  {
    id: "46",
    tittle: "City",
    isEnabled: true,
  },
];
