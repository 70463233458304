import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Stack,
  Snackbar,
} from "@mui/material";
import React from "react";
import { ShippingSchemaStyles } from "./style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useLocation } from "react-router-dom";
import { TextBox } from "../../components";
import { Styledselect } from "../addnewvendor/addvendor";
import { styled, useTheme } from "@mui/styles";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import DeleteIcon from "../../assets/deleteIcon";

// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';

const StyledLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "12px",
  // color: "#231535",
  marginBottom: "0.35em",
  "& .css-u0qe18-MuiFormLabel-root.Mui-focused": {
    color: "#00000099 !important",
  },
}));

let token = localStorage.getItem("auth_token")?.toString();
token = token?.substring(1, token.length - 1);

export const ShippingSchema = (props) => {

  const classes = ShippingSchemaStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  // console.log("props", location);

  const [vendor, setVendor] = React.useState()
  const [untouchedOption, setUntouchedOptions] = React.useState([])
  const [countryOptions, setCountryOptions] = React.useState([])
  const [cond, setCond] = React.useState([])

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const [state, setstate] = React.useState({
    addlist: [],
    listData: [],
    vendor_id: props?.vendor_id || location?.state?.ID,
    vendor_name: "",
    country_name: "",
    shipping_days: "",
    lead_time: "",
    error: {},
  });

  const {
    addlist,
    listData,
    vendor_id,
    vendor_name,
    shipping_days,
    lead_time,
    country_name,
  } = state;

  const handleClick = () => {
    let arr = cond;
    let opt_arr = countryOptions
    let index = opt_arr.indexOf(state.country_name)
    if(index > -1){
      opt_arr.splice(index, 1)
    }
    if (state.shipping_days && state.lead_time) {
      arr.push({
        country_name: state.country_name,
        shipping_days: state.shipping_days,
        lead_time: state.lead_time
      })
      setCountryOptions(opt_arr)
      setCond(arr)
      setstate({
        shipping_days: "",
        lead_time: ""
      })
    }
    else {
      setAlert({
        open: true,
        message: state.shipping_days === "" ? 'Shipping Days is mandatory' : state.lead_time === "" ? 'Lead Time is mandatory' : "",
        backgroundColor: "#FF3232",
      });
    }
  };

  const deleteState = (index) => {
    let arr = cond;
    let country = arr[index]
    // console.log(country.country_name)
    let country_opt = countryOptions || []
    country_opt?.push(country?.country_name)
    arr?.splice(index, 1)
    setCond(arr)
    setCountryOptions(country_opt)
    setstate({
      shipping_days: "",
      lead_time: ""
    })
  }

  const handleSave = () => {
    let arr = cond
    let flag = 0;
    let country_payload = {
      vendor_id: location.state.vendor_id,
      shipping_data: {
        shipping_days: {},
        lead_time: {}
      }
    }
    let noncountry_payload = {
      vendor_id: location.state.vendor_id,
      shipping_data: {}
    }

    arr.map((x) => {
      if (x.country_name) {
        country_payload.shipping_data.shipping_days[x.country_name] = x.shipping_days;
        country_payload.shipping_data.lead_time[x.country_name] = x.lead_time;
        flag = 0
      }
      else {
        noncountry_payload.shipping_data.shipping_days = x.shipping_days
        noncountry_payload.shipping_data.lead_time = x.lead_time
        flag = 1
      }
    })
    let payload = flag === 1 ? noncountry_payload : country_payload
    NetworkCall(
      `${config.api_url}shipping/data/upsert`,
      "POST",
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        // console.log(res)
        // setCountryOptions(res.data.column_data)
        setAlert({
          open: true,
          message: `Shipping Data Added Successfully`,
          backgroundColor: "#4BB543",
        });
      })
      .catch((err) => {
        // setLoading(false);
        setAlert({
          open: true,
          message: "Something went wrong. Please try again later.",
          backgroundColor: "#FF3232",
        });
      });
  }

  React.useEffect(() => {
    NetworkCall(
      `${config.api_url}shipping/data/get?vendor_id=${location.state.vendor_id}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        console.log(res?.data?.data?.shipping_data)
        let keys = Object.keys(res?.data?.data?.shipping_data)
        let country = Object.keys(res?.data?.data?.shipping_data?.lead_time)
        console.log(keys)
        console.log(country)
        let arr = []
        country.map(x => (
          arr.push({
            country_name: x,
            lead_time: res?.data?.data?.shipping_data?.lead_time[x],
            shipping_days: res?.data?.data?.shipping_data?.shipping_days[x]
          })
        ))
        setCond(arr)
      })
      .catch((err) => {
        // setLoading(false);
        // setAlert({
        //     open: true,
        //     message: "Something went wrong. Please try again later.",
        //     backgroundColor: "#FF3232",
        // });
      });
    NetworkCall(
      `${config.api_url}vendors/get/all?country_name=USA`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        let vendor = res.data?.data?.rows.filter((x) => x.id === location.state.vendor_id)
        setVendor(vendor[0])
        // setVendorOption(vendor_arr)
      })
      .catch((err) => {
        // setLoading(false);
        // setAlert({
        //     open: true,
        //     message: "Something went wrong. Please try again later.",
        //     backgroundColor: "#FF3232",
        // });
      });
  }, []);

  React.useEffect(() => {
    NetworkCall(
      `${config.api_url}feed/unqiue/data/get/countries`,
      "POST",
      {
        // column: 'Country',
        vendor_id: location.state.vendor_id
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        // console.log(res)
        setCountryOptions(res.data.column_data)
        setUntouchedOptions(res.data.column_data)
      })
      .catch((err) => {
        // setLoading(false);
        // setAlert({
        //     open: true,
        //     message: "Something went wrong. Please try again later.",
        //     backgroundColor: "#FF3232",
        // });
      });
  }, [vendor])

  return (
    <>
      {!props.isStepper && (
        <div className={classes.titlesection}>
          <ArrowBackIcon className="icon" onClick={() => history.goBack(-1)} />
          <Typography variant="subtitle2">Edit Shipping Schema</Typography>
        </div>
      )}
      <div className={classes.root}>
        <div className={classes.rootBody}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} style={{ borderRight: "1px solid #eee" }}>
              <div className={classes.addruleDrawer}>
                <div className={classes.addruleDrawerBody}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextBox
                        label={"Vendor Name"}
                        type={"text"}
                        value={vendor?.first_name ? vendor?.first_name : "" + " " + vendor?.last_name ? vendor?.last_name : ""}
                        isError={state?.error?.vendor_name}
                        errorMessage={
                          state?.error?.vendor_name &&
                          "Please enter the vendor name"
                        }
                        disabled
                        placeholder={"Vendor Name"}
                        onChange={(e) =>
                          setstate({
                            ...state,
                            vendor_name: e.target.value,
                            error: { ...state.error, vendor_name: false },
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <FormControl fullWidth>
                    <StyledLabel>
                      Select Country
                      {/* <sup style={{ color: theme.palette.error.main }}>*</sup> */}
                    </StyledLabel>
                    <Styledselect
                      sx={{ padding: "0px !important" }}
                      // labelId="demo-simple-select-label"
                      placeholder={`Network Call Methods`}
                      value={country_name}
                      required={true}
                      isRequired
                      label={`Network Call Methods`}
                      input={
                        <OutlinedInput sx={{ padding: "0px !important" }} />
                      }
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={(e) =>
                        setstate({
                          ...state,
                          country_name: e.target.value,
                          error: { ...state.error, country_name: false },
                        })
                      }
                    >
                      {countryOptions?.map((x) => {
                        return <MenuItem disabled={cond.filter(name => name.country_name === x)?.length > 0} value={x}>{x}</MenuItem>;
                      })}
                    </Styledselect>
                  </FormControl>
                  <br />
                  <Grid container spacing={2} style={{ marginTop: 8 }}>
                    <Grid item xs={6}>
                      <TextBox
                        label={"Shipping Days"}
                        type={"number"}
                        isRequired
                        value={state.shipping_days}
                        isError={state?.error?.shipping_days}
                        errorMessage={
                          state?.error?.shipping_days &&
                          "Please enter the shipping days"
                        }
                        placeholder={"Shipping Days"}
                        onChange={(e) =>
                          setstate({
                            ...state,
                            shipping_days: e.target.value,
                            error: { ...state.error, shipping_days: false },
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextBox
                        label={"Lead Time"}
                        type={"number"}
                        value={state.lead_time}
                        isRequired
                        isError={state?.error?.lead_time}
                        errorMessage={
                          state?.error?.lead_time && "Please enter the lead time"
                        }
                        placeholder={"Lead Time"}
                        onChange={(e) =>
                          setstate({
                            ...state,
                            lead_time: e.target.value,
                            error: { ...state.error, lead_time: false },
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Button style={{ marginTop: 20 }} variant="outlined" onClick={handleClick}>Add</Button>
                    </Grid>
                  </Grid>


                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} style={{ borderRight: "1px solid #eee" }}>
              <div
                style={{ background: "#fff", padding: 2, height: "60vh" }}
                className={classes.addruleDrawer}
              >
                <Typography variant="subtitle2" style={{ fontSize: 16, fontWeight: "bold", padding: "15px 15px" }}>Added Shipping Data</Typography>
                <Divider />
                <div style={{ height: cond?.length > 0 ? "47vh" : 0, overflow: "scroll" }}>
                  {cond.length > 0 ? (
                    <TableContainer >
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Country</TableCell>
                            <TableCell align="right">Shipping Days</TableCell>
                            <TableCell align="right">Lead Time</TableCell>
                            <TableCell align='right'> </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cond.map((row, index) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.country_name}
                                </TableCell>
                                <TableCell align="center">{row.shipping_days}</TableCell>
                                <TableCell align="center">{row.lead_time}</TableCell>
                                <TableCell align="right"><IconButton onClick={(e) => deleteState(index)} aria-label="delete" size="small"><DeleteIcon fontSize="inherit" /></IconButton></TableCell>
                              </TableRow>
                            )
                          })}

                        </TableBody>
                      </Table>
                    </TableContainer>) : (<></>)}
                </div>
              </div>
            </Grid>
            <div
              className={classes.footer}
            >
              <Button
                variant="contained"
                sx={{ background: theme.palette.button.primary }}
                onClick={() => handleSave()}
              >
                {vendor_id
                  ? props?.isStepper
                    ? "Save & Continue"
                    : "Update"
                  : "Save & Continue"}
              </Button>
            </div>
          </Grid>
        </div>
      </div>
      <Snackbar
        open={alert.open}
        message={alert.message}
        ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={4000}
      />
    </>
  );
};
