import {
  Box,
  Typography,
  Grid,
  Button,
  Snackbar,
  IconButton,
  Stack,
  useTheme,
  FormLabel,
  OutlinedInput,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { withNavBars } from "./../../HOCs";
import { styled } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TextBox, MobileNumberInputComponent } from "../../components";
import { currencyValue, genderValue } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { useHistory, useLocation } from "react-router-dom";

const StyledLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "12px",
  // color: "#231535",
  marginBottom: "0.35em",
  "& .css-u0qe18-MuiFormLabel-root.Mui-focused": {
    color: "#00000099 !important",
  },
}));
export const Styledselect = styled(Select)(({ theme }) => ({
  // fontSize: "12px",
  // color:"#231535",
  // marginBottom:"0.35em",
  label: {
    color: theme.palette.text.secondary,
  },
  borderRadius: "10px",
  "& .MuiOutlinedInput-input": {
    padding: "7px 14px !important",
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  // [`& fieldset`]: {
  //   "& :hover": {
  //     border: "0px solid #5078E1",
  //   },
  // },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CED3DD !important",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "16px 24px",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    display: "flex",
    marginBottom: 26,
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      fontWeight: 600,
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: 16,
    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
    },
  },
  addruleDrawer: {
    // width: 600,
    backgroundColor: "#fff",
    borderRadius: 6,
    zIndex: 1000,
    // [theme.breakpoints.down("sm")]: {
    //     width: 300,
    // },
  },
  addruleDrawerBody: {
    padding: 20,
    height: "75vh",
    overflow: "auto",
  },
}));
const initstate = {
  first_name: "",
  mobile: "",
  mobile_no_country_code: "+91",
  email_id: "",
  // description: "",
  // address1: "",
  // address2: "",
  gender: "",
  // city: "",
  // state: "",
  // country_name: "",
  user_role: "",
  // pin: "",
};

const AddUser = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation()
  const theme = useTheme();
  const [data, setData] = useState(initstate);
  const [roles, setRoles] = useState([]);
  const [isEdit, setIsEdit] = useState(location?.state?.isEdit || false)

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const setValue = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);
    NetworkCall(
      `${config.api_url}roles/get/all`,
      "GET",
      {},
      {
        // auth_token: "auth_token",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        console.log(res?.data?.rows);
        // setLoading(false);
        let data = res?.data?.rows;

        let arr = data.map((x) => {
          return {
            label: x.role_name,
            value: x.id,
          };
        });
        console.log(arr);
        setRoles(arr);
        // setIsEdit(false)
        // setCount(res?.data?.count);
      })
      .catch((err) => {
        // setLoading(false);
      });
  }, []);

  useEffect(() => {
    
    let temp_data = location?.state?.data;
    console.clear()
    console.log(temp_data)
    setData({
      ...data,
      first_name: temp_data?.first_name,
      email_id: temp_data?.email_id,
      user_role: temp_data?.role_id,
    })
    // setIsEdit(false)
  }, [location?.state?.data])

  const validation = () => {
    if (
      data?.first_name === "" ||
      data?.email_id === "" ||
      data?.user_role === "" ||
      data?.first_name === undefined ||
      data?.email_id === undefined ||
      data?.user_role === undefined
    ) {
      return false;
    } else {
      return true;
    }
  };

  const addUser = () => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);
    if (validation()) {
      let payload = {
        first_name: data?.first_name,
        email_id: data.email_id,
        role_id: data?.user_role,
      };
      if(isEdit){
        payload.user_id = location?.state?.data?.user_id
        payload.id = location?.state?.data?.id
      }
      NetworkCall(
        isEdit ? `${config.api_url}users/update` : `${config.api_url}users/create`,
        "POST",
        {
          ...payload,
        },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      )
        .then((res) => {
          console.log(res);
          setAlert({
            open: true,
            message: isEdit ? `User Updated Successfully` : `User Created Successfully`,
            backgroundColor: `#4BB543`,
          });
          setTimeout(() => {history.push("/usermanagement")}, 2000); 
        })
        .catch((res) => {
          console.clear()
          console.log(res.response?.data?.message);
          setAlert({
            open: true,
            message: `${res.response?.data?.message}`,
            backgroundColor: `#FF3232`,
          });
        });
    }
    else{
      setAlert({
        open: true,
        message: "Please fill the Mandatory field",
        backgroundColor: "#FF3232",
      });
    }
  };

  return (
    <Box className={classes.root}>
      <div className={classes.titleSection}>
        <ArrowBackIcon className="icon" onClick={() => history.goBack(-1)} />
        <Typography variant="subtitle2">{isEdit ? `Update User` : `Add User`}</Typography>
      </div>
      <div className={classes.addruleDrawer}>
        <div className={classes.addruleDrawerBody}>
          <Stack justifyContent="space-between" sx={{ height: "65vh" }}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12} lg={4}>
                <TextBox
                  label={"User Name"}
                  type={"text"}
                  isRequired
                  placeholder={"User Name"}
                  value={data?.["first_name"]}
                  onChange={(e) => setValue("first_name", e.target.value)}
                />
              </Grid>
              <Grid item md={4} xs={12} lg={4}>
                <TextBox
                  label={"Email Address"}
                  type={"email"}
                  isRequired
                  placeholder={"Email Address"}
                  value={data?.["email_id"]}
                  onChange={(e) => setValue("email_id", e.target.value)}
                />
              </Grid>
              <Grid item md={4} xs={12} lg={4}>
                <FormControl fullWidth>
                  <StyledLabel>
                    User Role
                    <sup style={{ color: theme.palette.error.main }}>*</sup>
                  </StyledLabel>
                  <Styledselect
                    sx={{ padding: "0px !important" }}
                    // labelId="demo-simple-select-label"
                    placeholder={`Network Call Methods`}
                    value={data?.["user_role"]}
                    required={true}
                    isRequired
                    label={`Network Call Methods`}
                    input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(e) => setValue("user_role", e.target.value)}
                  >
                    {roles?.map((x) => {
                      return <MenuItem value={x.value}>{x.label}</MenuItem>;
                    })}
                  </Styledselect>
                </FormControl>
              </Grid>
              {/* <Grid item md={4} xs={12} lg={4}>
                <MobileNumberInputComponent
                  label="Contact Number "
                  // isRequired
                  value={{
                    mobile: data?.mobile,
                    mobile_code: data?.mobile_no_country_code,
                  }}
                  handleChange={(value) => setValue("mobile", value.mobile)}
                  handleDropDown={(value) =>
                    setValue("mobile_no_country_code", value.mobile_code)
                  }
                />
              </Grid>

              <Grid item md={4} xs={12} lg={4}>
                <FormControl fullWidth>
                  <StyledLabel>
                    Gender
                  </StyledLabel>
                  <Styledselect
                    sx={{ padding: "0px !important" }}
                    // labelId="demo-simple-select-label"
                    placeholder={`Network Call Methods`}
                    value={data?.["gender"]}
                    // required={true}
                    // isRequired
                    label={`Network Call Methods`}
                    input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(e) => setValue("gender", e.target.value)}
                  >
                    {genderValue?.map((x) => {
                      return <MenuItem value={x.value}>{x.label}</MenuItem>;
                    })}
                  </Styledselect>
                </FormControl>
              </Grid> */}
              
            </Grid>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                className={classes.cancelButton}
                variant="outlined"
                onClick={() => {history.goBack(-1)}}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                sx={{ background: theme.palette.button.primary }}
                variant="contained"
                onClick={() => {
                  addUser();
                }}
              >
                { `Save`}
              </Button>
            </Stack>
          </Stack>
        </div>
      </div>
      <Snackbar
        open={alert.open}
        message={alert.message}
        ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={4000}
      />
    </Box>
  );
};

export default withNavBars(AddUser);
