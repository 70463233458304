import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Stack,
  IconButton,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";

import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { KeyboardBackspace } from "@mui/icons-material";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    // margin: "30px 28px 0px 28px",
    // backgroundColor: theme.palette.common.white,
    padding: "10px 20px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: '18px 28px'
  },
  section: {
    textAlign: "left",
    margin: "0px 28px",
    // backgroundColor: theme.palette.common.white,
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  section2: {
    textAlign: "left",
    margin: "0px 28px",
    // backgroundColor: theme.palette.common.white,
    padding: "0px 20px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tittle: {
    fontWeight: 600,
    fontSize: "20px",
    marginBottom: "0px",
  },
  subtittle: {
    fontSize: 14,
    fontWeight: 600,
  },
  subtittle2: {
    // fontWeight: 600,
    fontSize: 14,
    color: theme.palette.common.darkgrey,
  },
  data: {
    fontSize: 14,
    fontWeight: 500,
  },
  data2: {
    fontSize: 14,
    fontWeight: 550,
    color: theme.palette.common.black,
  },
  view: {
    "& .MuiGrid-item": {
      paddingTop: "15px",
    },
  },
  mapbtn: {
    textAlign: "left",
    margin: "20px 28px 100px",
  },
}));

const StyledDivider = styled(Divider)(() => ({
  borderColor: "#EAEAEA",
}));

export const ViewVendor = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation(); 
  const [data, setData] = useState({})
  const vendor_id = location.state?.vendor_id || "33812502-b1b5-49b8-ba01-7ea67f5e8186";

  useEffect(() => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);
    NetworkCall(
      `${config.api_url}vendors/get`,
      "POST",
      { vendor_id: vendor_id },
      {
        // auth_token: "auth_token",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    ).then((res) => {
      console.log(res.data?.data)
      setData(res.data?.data)
    }).catch((err) => {});
  }, []);

  const back = () => {
    history.goBack(-1);
  };
  const editVendor = () => {
    history.push({
      pathname: "/edit",
      state: {
        vendor_id: location?.state?.ID ?? '',
      }
    });
  };

  const viewVendor = (data) => {
    if (data === "vendor") {
      history.push({
        pathname: "/addvendorrule",
        state: {
          ID: location?.state?.ID ?? "",
          Location: "/viewvendor",
        },
      });
    } else if (data === 'margin') {
      history.push({
        pathname: "/addmarginrule",
        state: {
          ID: location?.state?.ID ?? '',
          Location: '/viewvendor'
        }
      });
    } else if (data === "apiconfigure"){
      history.push({
        pathname: "/apiconfigure",
        state: {
          vendor_id: location?.state?.ID ?? '',
          Location: '/viewvendor'
        }
      });
    } else if(data === "dbschema"){
      history.push({
        pathname: "/schema",
        state: {
          vendor_id: location?.state?.ID ?? '',
          Location: '/viewvendor'
        }
      });
    } else {
      history.push({
        pathname: "/addrenamerule",
        state: {
          ID: location?.state?.ID ?? "",
          Location: "/viewvendor",
        },
      });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100vh",
        margin: "28px",
        borderRadius: 6,
      }}
    >
      {/* <Stack direction={`column`} divider={<StyledDivider orientation="horizontal" flexItem />}> */}
      <div className={classes.root}>
        <Typography className={classes.tittle}>
          <IconButton onClick={back}>
            <KeyboardBackspace />
          </IconButton>
          Vendor Details
        </Typography>
        <Button variant="contained" color="primary" onClick={editVendor}>
          Edit
        </Button>
      </div>

      <StyledDivider orientation="horizontal" flexItem />
      <Grid container className={classes.view}>
        {/* name */}
        <Stack
          direction={{ xs: `column`, md: `column` }}
          divider={<StyledDivider orientation="horizontal" flexItem />}
        >
          <Stack
            direction={{ xs: `column`, md: "row" }}
            divider={<StyledDivider orientation="vertical" flexItem />}
          >
            <Grid item md={4} xs={12}>
              {/* name */}
              <div className={classes.section}>
                <Stack gap={1}>
                  <Typography className={classes.subtittle2}>
                    <Stack direction={`row`} gap={1}>
                      <PermIdentityOutlinedIcon />
                      <span className={classes.data2}>
                        {data?.first_name}
                      </span>
                    </Stack>
                  </Typography>
                  <Typography className={classes.subtittle2}>
                    <Stack direction={`row`} gap={1}>
                      <EmailOutlinedIcon style={{ fontSize: 20 }} />
                      <span className={classes.data2}>
                        {data?.email_id}
                      </span>
                    </Stack>
                  </Typography>
                  <Typography className={classes.subtittle2}>
                    <Stack direction={`row`} gap={1}>
                      <CallOutlinedIcon style={{ fontSize: 20 }} />
                      <span className={classes.data2}>{data?.mobile && data?.mobile_no_country_code ? `${data?.mobile_no_country_code}-${data?.mobile}` : `-`}</span>
                    </Stack>
                  </Typography>
                </Stack>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className={classes.section}>
                {/* <Typography className={classes.subtittle}>
              Name : <span className={classes.data}>Jon</span>
            </Typography> */}
                <Stack gap={1}>
                  <Typography className={classes.subtittle}>
                    <Stack direction={`row`} gap={1}>
                      <Typography className={classes.subtittle2}>
                        Status :
                      </Typography>
                      <span className={classes.data}>{data?.is_active ? `Active` : `Inactive`}</span>
                    </Stack>
                  </Typography>
                  <Typography className={classes.subtittle}>
                    <Stack direction={`row`} gap={1}>
                      <Typography className={classes.subtittle2}>
                        Created At :
                      </Typography>
                      <span className={classes.data}>{moment(data?.created_at)
                            .tz(moment.tz.guess())
                            .format()}</span>
                    </Stack>
                  </Typography>
                  <Typography className={classes.subtittle}>
                    <Stack direction={`row`} gap={1}>
                      <Typography className={classes.subtittle2}>
                        Updated At :
                      </Typography>
                      <span className={classes.data}>{moment(data?.updated_at)
                            .tz(moment.tz.guess())
                            .format()}</span>
                    </Stack>
                  </Typography>
                </Stack>
              </div>
            </Grid>
            {/* email */}
            <Grid item md={5} xs={12}>
              {/* email  */}
              <div className={classes.section}>
                <Typography className={classes.subtittle2}>
                  Address :{" "}
                  <div className={classes.data2}>
                    {" "}
                    14, First floor, Ferouz Complex, Anna Nagar Main Road,
                    opposite to Anna Nagar Post Office, Anna Nagar, Madurai,
                    Tamil Nadu 625020
                  </div>
                </Typography>
              </div>
            </Grid>
          </Stack>

          <Stack
            direction={{ xs: `column`, md: `row` }}
            divider={<StyledDivider orientation="vertical" flexItem />}
          >
            <Grid item md={6}>
              {/*  description  */}
              {/* <StyledDivider orientation="horizontal" flexItem /> */}
              {/* <div className={classes.section}>
                <Typography className={classes.subtittle2}>
                  Description :{" "}
                  <div className={classes.data2}>
                    Models promote, advertise, and showcase clothing, footwear,
                    and other products. They participate in photoshoots, fashion
                    shows, commercials, trade shows, and conventions as well as
                    pose for sculptors, artists, and painters.
                  </div>
                </Typography>
              </div> */}
              <div className={classes.section2}>
                <Typography className={classes.subtittle}>
                  Feed Details
                </Typography>
                <Button variant="outlined" color="primary" onClick={() => viewVendor("apiconfigure")}>
                  View
                </Button>
              </div>
              <br />
              <div className={classes.section2}>
                <Typography className={classes.subtittle}>
                  Map DB Schema
                </Typography>
                <div>
                  {/* <Button variant="outlined" color="primary">
                Change
              </Button> */}
                  {/* <IconButton>
                      <EditOutlinedIcon />
                    </IconButton> */}
                  {/* <IconButton><CheckOutlinedIcon/></IconButton> */}
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => viewVendor("dbschema")}
                  >
                    View
                  </Button>
                </div>
              </div>
            </Grid>

            <Grid item md={6}>
              <Stack gap={2}>
                <div className={classes.section2}>
                  <Typography className={classes.subtittle}>
                    Vendor Rules
                  </Typography>
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => viewVendor("vendor")}
                    >
                      View
                    </Button>
                  </div>
                </div>
                <div className={classes.section2}>
                  <Typography className={classes.subtittle}>
                    Rename Rules
                  </Typography>
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => viewVendor("rename")}
                    >
                      View
                    </Button>
                  </div>
                </div>
                <div className={classes.section2}>
                  <Typography className={classes.subtittle}
                  >
                    Margin Rules
                  </Typography>
                  <div>
                    <Button variant="outlined" color="primary"
                      onClick={() => viewVendor('margin')}>
                      View
                    </Button>
                  </div>
                </div>
              </Stack>
            </Grid>
          </Stack>
        </Stack>
      </Grid>
      {/* </Stack> */}

      {/* <div className={classes.mapbtn}>
        <Button
          color="primary"
          variant="contained"
          style={{ textTransform: "capitalize" }}
          onClick={back}
        >
          Back
        </Button>
      </div> */}
    </div>
  );
};
