import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { TextBox, MobileNumberInputComponent } from "../../components";
import { styled } from "@mui/styles";
import {
    Grid,
    Typography,
    Button,
    Snackbar,
    // IconButton,
    Stack,
    useTheme,
    FormLabel,
    OutlinedInput,
    Select,
    FormControl,
    MenuItem,
} from "@mui/material";
import { Styledselect } from "../addnewvendor/addvendor";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import SearchIcon from '@mui/icons-material/Search';

let token = localStorage.getItem("auth_token")?.toString();
token = token?.substring(1, token.length - 1);

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "20px 10rem 0px",
        [theme.breakpoints.down('md')]: {
            margin: "20px 1rem 0px",
        },
    },
    button: {
        // margin: theme.spacing(1),
        marginTop: 24,
        lineHeight: 1,
        [theme.breakpoints.down("sm")]: {
            minWidth: 32,
            paddingLeft: 8,
            paddingRight: 8,

            "& .MuiButton-startIcon": {
                margin: 0
            }
        }
    },
    buttonText: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    rootBody: {
        textAlign: "left",
        backgroundColor: theme.palette.common.white,
        padding: "20px",
        borderRadius: 8,
        border: "1px solid #DCDCDC",
    },
    titleSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        paddingLeft: 10,
        // borderBottom: "1px solid #ced3dd",
        "& .MuiTypography-subtitle2": {
            fontWeight: 600,
            fontSize: 16,
        },
        "& svg": {
            cursor: "pointer",
        },
        height: 46,
        maxHeight: 46,
        marginBottom: 16,
        "& .icon": {
            background: "#ffffff",
            boxShadow: "#d9d9d97a 0px 20px 25px",
            borderRadius: 50,
            padding: 4,
            width: "30px",
            height: "30px",
            marginRight: 8,
        },
    },
    create: {
        cursor: "pointer",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
    },
    search: {
        backgroundColor: theme.palette.common.white,

        border: "0px !important",
        borderRadius: "12px !important",
        boxShadow: "0px 20px 25px #D9D9D97A",
    },
    noBorder: {
        border: "none",
    },
    title: {
        fontSize: 16,
        fontWeight: "bold",
        paddingLeft: 15,
        height: "fit-content",
        margin: "auto 0px",
    },
}))

const StyledLabel = styled(FormLabel)(({ theme }) => ({
    fontSize: "12px",
    // color: "#231535",
    marginBottom: "0.35em",
    "& .css-u0qe18-MuiFormLabel-root.Mui-focused": {
        color: "#00000099 !important",
    },
}));

export const Diamond = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const location = useLocation();
    const [vendor, setVendor] = useState(null);
    const [vendorOption, setVendorOption] = useState([])
    const [diamond, setDiamond] = useState();
    const [diamondOption, setDiamondOption] = useState([])
    const [status, setStatus] = useState()
    const [oldStatus, setOldStatus] = useState()

    const [diamondid, setDiamondID] = useState();
    const [state, setState] = useState();

    const [alert, setAlert] = React.useState({
        open: false,
        message: "",
        backgroundColor: "",
    });


    useEffect(async () => {
        // Making API call for Loading Vendor Options
        // setVendorOption([1, 2])

        NetworkCall(
            `${config.api_url}vendors/get/all?country_name=USA`,
            "GET",
            {},
            {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        )
            .then((res) => {
                let vendor_arr = res.data?.data?.rows.map((x) => {
                    let first = x.first_name ? x.first_name : ""
                    let last = x.last_name ? x.last_name : ""
                    let name = first + " " + last
                    return {
                        id: x.id,
                        name
                    }
                })
                setVendorOption(vendor_arr)
            })
            .catch((err) => {
                // setLoading(false);
                // setAlert({
                //     open: true,
                //     message: "Something went wrong. Please try again later.",
                //     backgroundColor: "#FF3232",
                // });
            });
    }, [])

    useEffect(async () => {
        // Making API call for Loading Diamond Options

        await NetworkCall(
            `${config.api_url}diamond/get/all?vendor_id=${vendor}`,
            "GET",
            {},
            {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        )
            .then((res) => {
                // console.clear()
                // console.log(res?.data)
                var result = Object.keys(res?.data).map((key) => Number(key) ? res?.data[key] : "").filter(Boolean);
                // console.log(result)
                setDiamondOption(result)
            })
            .catch((err) => {
                // setLoading(false);
                // setAlert({
                //     open: true,
                //     message: "Something went wrong. Please try again later.",
                //     backgroundColor: "#FF3232",
                // });
            });
    }, [vendor])

    const fetchStatus = () => {
        if (diamondid !== undefined) {
            NetworkCall(
                `${config.api_url}diamond/status/get?feed_id=${diamondid}`,
                "GET",
                {},
                {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            )
                .then((res) => {
                    // console.log(res?.data?.result)
                    setAlert({
                        open: true,
                        message: `Diamond status has been Fetched successfully`,
                        backgroundColor: "#4BB543",
                    });
                    setState(res?.data?.result)

                    // history.push("/apiconfigure", { vendor_id: res?.data?.vendor_id });
                })
                .catch((err) => {
                    setAlert({
                        open: true,
                        message: "Something went wrong. Please try again later.",
                        backgroundColor: "#FF3232",
                    });
                });
        }
        else {
            setAlert({
                open: true,
                message: "Please Enter Diamond ID / Feed ID",
                backgroundColor: "#FF3232",
            });
        }
    }

    const updateStatus = () => {
        let payload = {
            "feed_id": diamondid,
            "status": status
        }
        console.log(diamond)

        console.log(payload)

        NetworkCall(
            `${config.api_url}diamond/status/update/all`,
            "POST",
            { ...payload },
            {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        )
            .then((res) => {
                setState(null)
                // setDiamondID(null)
                setAlert({
                    open: true,
                    message: `Diamond status has been updated successfully`,
                    backgroundColor: "#4BB543",
                });

                // history.push("/apiconfigure", { vendor_id: res?.data?.vendor_id });
            })
            .catch((err) => {
                setAlert({
                    open: true,
                    message: "Something went wrong. Please try again later.",
                    backgroundColor: "#FF3232",
                });
            });
    }

    const updateDiamondStatus = (e) => {

        let selectedDiamond = diamondOption.filter(x => x.FeedID === e.target.value)[0]
        console.log(selectedDiamond)
        setDiamond(selectedDiamond.ID)
        setOldStatus(selectedDiamond.Status)
    }


    return (
        <div className={classes.root}>
            <div className={classes.titleSection}>
                <Typography variant="subtitle2">
                    Diamond Status
                </Typography>
            </div>

            <div className={classes.rootBody}>
                <>
                    <Grid container spacing={2}>
                        <Grid item lg={10} md={10} sm={10} xs={10}>
                            {/* <FormControl fullWidth> */}
                            {/* <StyledLabel>
                                Select Vendor
                                <sup style={{ color: theme.palette.error.main }}>*</sup>
                            </StyledLabel>
                            <Styledselect
                                sx={{ padding: "0px !important" }}
                                // labelId="demo-simple-select-label"
                                placeholder={`Network Call Methods`}
                                value={vendor}
                                required={true}
                                isRequired
                                label={`Network Call Methods`}
                                input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={(e) => setVendor(e.target.value)}
                            >
                                {vendorOption?.map((x) => {
                                    return <MenuItem value={x.id}>{x.name}</MenuItem>;
                                })}
                            </Styledselect> */}
                            <TextBox
                                label={"Enter Diamond ID / Feed ID"}
                                type={"text"}
                                isRequired
                                // disabled
                                placeholder={"Diamond ID / Feed ID"}
                                value={diamondid}
                                // helperText="Non Editable"
                                onChange={(e) => setDiamondID(e.target.value)}
                            />
                            {/* </FormControl> */}
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <Button
                                sx={{ background: theme.palette.button.primary }}
                                variant="contained"
                                className={classes.button}
                                startIcon={<SearchIcon />}
                                onClick={() => fetchStatus()}
                            >
                                <span className={classes.buttonText}>{"Search"}</span>
                            </Button>
                        </Grid>
                    </Grid>
                    {
                        state ? (
                            <>
                                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={2}>
                                            <TextBox
                                                label={"Vendor Name"}
                                                type={"text"}
                                                // isRequired
                                                disabled
                                                placeholder={"Vendor Name"}
                                                value={state?.vendor?.first_name}
                                            />
                                            <TextBox
                                                label={"ID"}
                                                type={"text"}
                                                // isRequired
                                                disabled
                                                placeholder={"ID"}
                                                value={state?.ID}
                                            />

                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={2}>
                                            <TextBox
                                                label={"Feed ID"}
                                                type={"text"}
                                                // isRequired
                                                disabled
                                                placeholder={"Feed ID"}
                                                value={state?.FeedID}
                                            />
                                            <TextBox
                                                label={"Status"}
                                                type={"text"}
                                                // isRequired
                                                disabled
                                                placeholder={"Status"}
                                                value={state?.Status}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (<></>)
                    }
                    {/* {
                        vendor ? (
                            <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                                <StyledLabel>
                                    Select Diamond
                                    <sup style={{ color: theme.palette.error.main }}>*</sup>
                                </StyledLabel>
                                {console.log(diamond)}
                                <Styledselect
                                    sx={{ padding: "0px !important" }}
                                    // labelId="demo-simple-select-label"
                                    placeholder={`Network Call Methods`}
                                    value={diamond?.ID}
                                    required={true}
                                    isRequired
                                    label={`Network Call Methods`}
                                    input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                                    inputProps={{ "aria-label": "Without label" }}
                                    onChange={(e) => updateDiamondStatus(e)}
                                >
                                    {diamondOption?.map((x) => {
                                        return <MenuItem value={x.FeedID}>{x.ID}</MenuItem>;
                                    })}
                                </Styledselect>
                            </FormControl>
                        ) : (<></>)
                    } */}
                    {
                        state?.Status === 'Active' ? (
                            <>
                                <Grid container spacing={2} sx={{ marginTop: "0.5rem" }}>
                                    {/* <Grid item xs={12} md={6}>
                                        
                                        <TextBox
                                            label={"Current Diamond Status"}
                                            type={"text"}
                                            // isRequired
                                            disabled
                                            placeholder={"Current Diamond Status"}
                                            value={oldStatus}
                                        // helperText="Non Editable"
                                        // onChange={(e) => setValue("first_name", e.target.value)}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <Stack
                                            gap={2}
                                            direction={"row"}
                                            justifyContent={"flex-end"}
                                        // sx={{ mt: 2 }}
                                        >
                                            <FormControl fullWidth >
                                                <StyledLabel>
                                                    Select Diamond Status
                                                    <sup style={{ color: theme.palette.error.main }}>*</sup>
                                                </StyledLabel>
                                                <Styledselect
                                                    sx={{ padding: "0px !important" }}
                                                    // labelId="demo-simple-select-label"
                                                    placeholder={`Network Call Methods`}
                                                    value={status}
                                                    required={true}
                                                    isRequired
                                                    label={`Network Call Methods`}
                                                    input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                                                    inputProps={{ "aria-label": "Without label" }}
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    {['Inactive', 'Sold']?.map((x) => {
                                                        return <MenuItem value={x}>{x}</MenuItem>;
                                                    })}
                                                </Styledselect>
                                            </FormControl>
                                            {status ?
                                                <>
                                                    <Button
                                                        sx={{ background: theme.palette.button.primary}}
                                                        variant="contained"
                                                        className={classes.button}
                                                        onClick={() => updateStatus()}
                                                    >
                                                        {"Update"}
                                                    </Button>
                                                </>
                                                : (<></>)}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (<></>)
                    }
                </>
            </div>

            <Snackbar
                open={alert.open}
                message={alert.message}
                ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setAlert({ ...alert, open: false })}
                autoHideDuration={4000}
            />
        </div>
    )
}