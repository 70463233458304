import { TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.palette.text.secondary,
    fontSize: "12px",
    textAlign: "left",
    "& sup": {
      color: theme.palette.error.main,
    },
  },
  text: {},
  textbox: {
    [`& fieldset`]: {
      borderRadius: "8px",
      height: (props) => (props.multiline ? "unset" : 40),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: `0px solid ${theme.palette.primary.main}`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "7px 14px",
      fontSize: "14px",
      color: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CED3DD !important",
    },
  },
}));
export const TextBox = (props) => {
  const classes = useStyles(props);
  const getLabel = (props) => {
    return (
      <Typography variant="body1" className={classes.Label} gutterBottom>
        {props.label} {!props.isRequired ? <></> : <sup>*</sup>}
      </Typography>
    );
  };
  return (
    <div className={classes.root}>
      <>
        {getLabel(props)}
        <div className={classes.text}>
          <TextField
            className={classes.textbox}
            type={props.type}
            placeholder={props.placeholder}
            variant={props.variant ?? "outlined"}
            fullWidth
            InputLabelProps={{
              shrink: false,
            }}
            inputProps={{ readOnly: props?.isReadonly ?? false }}
            disabled={props?.disabled ?? false}
            size="small"
            multiline={props.multiline}
            rows={props.rows}
            rowsMax={10}
            onChange={props.onChange}
            value={props.value}
            onKeyPress={props.onKeyPress}
            helperText={props?.helperText}
          />
        </div>
      </>
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};
TextBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
};
TextBox.defaultProps = {
  label: "Textbox",
  multiline: false,
  type: "text",
  placeholder: "Type Here...",
};
