import {
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import { Add, CenterFocusStrong } from "@mui/icons-material";
import { TabelComponent } from "../../components";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: 15,
    height: "fit-content",
    margin: "auto 0px",
  },
  create: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  search: {
    backgroundColor: theme.palette.common.white,
    // padding: 5,
    border: "0px !important",
    borderRadius: "12px !important",
    boxShadow: "0px 20px 25px #D9D9D97A",
  },
  noBorder: {
    border: "none",
  },
}));

const Tittles = [
  // { tittle: "S.No" },
  { tittle: "Name", align: "left" },
  { tittle: "Email Address", align: "left" },
  { tittle: "Role", align: "left" },
  { tittle: "Contact Number" },
  // { tittle: "Status", align: "left" },
  // { tittle: "Status" },
  // { tittle: "Created At" },
  // { tittle: "Updated At" },
  // { tittle: "Created by" },
  // { tittle: "updated by" },
  // { tittle: "Address" },
  { tittle: "Action" },
  // { tittle: "Edit" },
  // { tittle: "Delete" },
];

const ManageUsers = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [offset, setOffset] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const editUser = (data) => {
    history.push("/adduser", { data: data, isEdit: true, IndexMenu: 4 });
  };

  useEffect(() => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);
    NetworkCall(
      `${config.api_url}users/get/all`,
      "GET",
      {},
      {
        // auth_token: "auth_token",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        console.log(res?.data?.data?.rows);
        setLoading(false);
        setData(res?.data?.data?.rows || res?.data?.data);
        setCount(res?.data?.data?.count);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [limit, offset]);

  return (
    <>
      <div className={classes.root}>
        <div
          style={{
            textAlign: "right",
            marginBottom: "25px",
            // marginTop: "25px",
          }}
        >
          <Stack flexDirection="row" justifyContent="space-between">
            <Typography
              className={classes.title}
            >{`Total Users (${count})`}</Typography>
            <div>
              <TextField
                // variant="standard"
                size="small"
                type="search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                placeholder="Search by Name"
                className={classes.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  classes: { notchedOutline: classes.noBorder },
                }}
              />
              &nbsp;&nbsp;
              <IconButton
                className={classes.create}
                onClick={() => {
                  history.push("/adduser", { isEdit: false, IndexMenu: 4 });
                }}
              >
                <Add />{" "}
              </IconButton>
              {/* <Button className={classes.create} variant='contained' onClick={viewPage}>+ Add Vendor</Button> */}
            </div>
          </Stack>
        </div>
      </div>
      <TabelComponent
        state={data}
        loading={loading}
        tittles={Tittles}
        setRowsPerPage={setLimit}
        setPage={setOffset}
        page={offset}
        rowsPerPage={limit}
        count={count}
        edit={(data) => editUser(data)}
        search={search}
        isViewNeeded={false}
        isEditNeeded={true}
        hideContact={true}
        // viewVendor={viewVendor}
      />
    </>
  );
};

export default ManageUsers;
