import React, { useEffect } from "react";
import { TextField, InputAdornment, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TabelComponent } from "./TableComponent/TableComponent";
import SearchIcon from "@mui/icons-material/Search";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

import { UseDebounce } from "../../components";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    margin: "10px 28px",
  },
  create: {
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  search: {
    backgroundColor: theme.palette.common.white,

    border: "0px !important",
    borderRadius: "12px !important",
    boxShadow: "0px 20px 25px #D9D9D97A",
  },
  noBorder: {
    border: "none",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: 15,
    height: "fit-content",
    margin: "auto 0px",
  },
}));

// Constant Data
const Titles = [
  { title: "Vendor Name", align: "left" },
  { title: "Schedule Name", align: "left" },
  { title: "Schedule Time", align: "left" },
  { title: "Error Message", align: "left" },
  { title: "CSV File", align: "left" },
];

export const ErrorLogsHome = (props) => {
  const classes = useStyles();

  const debounce = UseDebounce();
  // States
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [offset, setOffset] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  //   SideEffects
  useEffect(() => {
    GetAllData(limit, offset);
  }, [limit, offset]);

  // Handle Functions

  const handleSearch = (e) => {
    setSearch(e);
    debounce(() => searchTableFunction(e), 800);
  };

  //   Fetch Functions
  // Get All Listing Data
  const GetAllData = async (limit, offset) => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);
    await NetworkCall(
      `${config.api_url}files/get/error?limit=${limit}&offset=${offset}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        setLoading(false);
        setCount(res?.data?.data?.count);
        setData(res?.data?.data?.rows || res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const searchTableFunction = (text) => {
    let api_url;
    if (text !== "") {
      api_url = `${config.api_url}files/get/error?limit=${limit}&offset=${offset}&search=${text}`;
    } else {
      api_url = `${config.api_url}files/get/error?limit=${limit}&offset=${offset}`;
    }
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);
    NetworkCall(
      `${api_url}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        setLoading(false);
        setCount(res?.data?.data?.count);
        setData(res?.data?.data?.rows || res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div className={classes.root}>
      <div
        style={{ textAlign: "right", marginBottom: "25px", marginTop: "25px" }}
      >
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography
            className={classes.title}
          >{`Total Error Logs (${count})`}</Typography>

          <div>
            <TextField
              size="small"
              type="search"
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
              placeholder="Search by Statistics Name"
              className={classes.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                classes: { notchedOutline: classes.noBorder },
              }}
            />
            &nbsp;&nbsp;
          </div>
        </Stack>
      </div>

      <TabelComponent
        state={data}
        loading={loading}
        titles={Titles}
        setRowsPerPage={setLimit}
        setPage={setOffset}
        page={offset}
        rowsPerPage={limit}
        count={count}
        search={search}
      />
    </div>
  );
};
