// import React, { useContext } from "react";
import { TextField, InputAdornment, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
// import { TextField, InputAdornment } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TabelComponent } from "../../components";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";
import { ListContext } from "../../contexts/listcontext";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { Add, CenterFocusStrong } from "@mui/icons-material";
import { UseDebounce } from '../../components';
import { NiceTable } from "../../components/tabelcomponent/niceTable";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    margin: "10px 28px",
  },
  create: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover":{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    }
  },
  search:{
    backgroundColor: theme.palette.common.white,
    // padding: 5,
    border: "0px !important",
    borderRadius: "12px !important",
    boxShadow: "0px 20px 25px #D9D9D97A",
  },
  noBorder: {
    border: "none",
  },
  title:{
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: 15,
    height:"fit-content",
    margin:"auto 0px",
  }
}));

const Tittles = [
  // { tittle: "S.No" },
  { tittle: "Vendor name", align: "left" },
  { tittle: "Email Address", align: "left" },
  { tittle: "Contact Number" },
  // { tittle: "Status" },
  // { tittle: "Created At" },
  // { tittle: "Updated At" },
  // { tittle: "Created by" },
  // { tittle: "updated by" },
  { tittle: "Address" },
  { tittle: "Action" },
  // { tittle: "Edit" },
  // { tittle: "Delete" },
];

export const Listing = (props) => {
  const { list } = useContext(ListContext);
  const history = useHistory();
  const classes = useStyles();

  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState([])
  const [count, setCount] = React.useState(0);
  const [limit, setLimit] = React.useState(5)
  const [offset, setOffset] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const debounce = UseDebounce();

  const viewVendor = (data) => {
    
    history.push({
      pathname: "/viewvendor",
      state: {
        vendor_id: data?.id ?? '',
        ID: data?.id ?? '',
        id: data?.id ?? '',
        IndexMenu: 1
      }
    });
  };

  const editVendor = (data) => {
    history.push({
      pathname: "/edit",
      state: {
        vendor_id: data?.id ?? '',
        IndexMenu: 1
      }
    });
  };
  const viewPage = () => {
    history.push({ pathname: '/addvendor', state : {IndexMenu: 1}})
  }

  const searchTableFunction =(text)=>{
    let api_url;

   if(text !== ""){
      api_url = `${config.api_url}vendors/get/all?limit=${limit}&offset=${offset}&search=${text}`
   }
   else{
    api_url = `${config.api_url}vendors/get/all?limit=${limit}&offset=${offset}`
   }

    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);

    NetworkCall(
     `${api_url}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    ).then((res)=>{
      setLoading(false)
      setData(res?.data?.data?.rows || res?.data?.data)
      setCount(res?.data?.data?.count)
    })
    .catch(err =>{
      setLoading(false)
    })
  }

  const handleSearch = (e) => {
    setSearch(e)
    debounce(() => searchTableFunction(e), 800)
  }

  useEffect(() => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token.substring(1, token.length - 1)
    NetworkCall(
      `${config.api_url}vendors/get/all?limit=${limit}&offset=${offset}`,
      "GET",
      {},
      {
        // auth_token: "auth_token",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    ).then((res) => {
      console.log(res?.data?.data?.rows)
      setLoading(false)
      setData(res?.data?.data?.rows || res?.data?.data)
      setCount(res?.data?.data?.count)
    })
      .catch((err) => {
        setLoading(false)
      });;
  }, [limit, offset]);

  return (
    <div className={classes.root}>
      <div style={{ textAlign: "right", marginBottom: "25px", marginTop:"25px" }}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography className={classes.title}>{`Total Vendors (${count})`}</Typography>
          <div>
        <TextField
          // variant="standard"
          size="small"
          type="search"
          onChange={(e) => handleSearch(e.target.value)}
          value={search}
          placeholder="Search by Name"
          className={classes.search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            classes:{notchedOutline:classes.noBorder}
          }}
        />&nbsp;&nbsp;
        <IconButton className={classes.create} onClick={viewPage}><Add/> </IconButton>
        {/* <Button className={classes.create} variant='contained' onClick={viewPage}>+ Add Vendor</Button> */}
        </div>
        </Stack>
      </div>

      <NiceTable
        state={data}
        loading={loading}
        tittles={Tittles}
        setRowsPerPage={setLimit}
        setPage={setOffset}
        page={offset}
        rowsPerPage={limit}
        count={count}
        edit={editVendor}
        search={search}
        viewVendor={viewVendor}
      />
    </div>
  );
};
