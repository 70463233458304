import React from "react";
import { withAllContexts, withNavBars } from "./../../HOCs";
import ListContextProvider from "../../contexts/listcontext";
import { Diamond } from "./diamond";

class DiamondParent extends React.Component {
  render() {
    return (
      <ListContextProvider>
        <Diamond/>
      </ListContextProvider>
    );
  }
}

export default withNavBars(withAllContexts(DiamondParent));
