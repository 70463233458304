import React, { useState } from "react";
import { Subheader, DrawerComponent } from "../../components";
import { useStyles } from "./style";
import {
  Typography,
  Grid,
  Snackbar,
  Stack,
  Pagination,
  Card,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { UseDebounce } from "../../components";
import { ListItem } from "./Components/listitem";

const pageSize = 5;

export const ParingLogic = (props) => {
  const classes = useStyles(props);

  const history = useHistory();
  const [page, setPage] = useState(1);

  //debounce hook
  const debounce = UseDebounce();

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const [search, setSearch] = useState(null);
  const [isView, setIsview] = useState(false);

  const [state, setstate] = React.useState({
    isopen: false,
    addlist: [],
    obj: {
      fact: "",
      operator: "",
      value: [""],
      path: "payload",
    },
    listData: [],

    rule_name: "",
    id: "",
    choosevendor: {},
    error: {},
  });
  React.useEffect(() => {
    history.push({
      state: {
        IndexMenu: 7,
      },
    });
    // eslint-disable-next-line
  }, []);
  const searchTableFunction = (text) => {
    let api_url;

    if (text !== "") {
      api_url = config.api_url + `pairing/rules/get/all?search=${text}`;
    } else {
      api_url = config.api_url + `pairing/rules/get/all`;
    }

    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);

    NetworkCall(
      `${api_url}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((response) => {
        const data = response.data?.rows?.map((v, i) => {
          if (v?.id) {
            return {
              ...v,
              id: v?.id,
              last_modifyed: moment(v?.updated_at).format("YYYY-MM-DD"),
              created_at: moment(v?.created_at).format("YYYY-MM-DD"),
            };
          }
        });

        setstate({
          ...state,
          listData: data,

          isopen: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (e) => {
    debounce(() => searchTableFunction(e), 800);
  };

  const isGetList = async () => {
    const api_url = config.api_url + "pairing/rules/get/all";

    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);

    new Promise((resolve, reject) => {
      axios
        .get(`${api_url}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          const data = response.data?.rows?.map((v, i) => {
            if (v?.id) {
              return {
                ...v,
                id: v?.id,
                last_modifyed: moment(v?.updated_at).format("YYYY-MM-DD"),
                created_at: moment(v?.created_at).format("YYYY-MM-DD"),
              };
            }
          });

          setstate({
            ...state,
            listData: data,

            isopen: false,
          });
          resolve(true);
        });
    });
  };

  React.useEffect(() => {
    isGetList();
    // eslint-disable-next-line
  }, []);

  const ishandleOpen = (data, isClone) => {
    setIsview(!isView);
    setstate({
      ...state,

      rule_name: data?.rule_name,
      addlist: data?.rules_data?.all,
      id: data?.id ?? "",
    });
  };

  const ishandleEdit = (data) => {
    history.push(`/paring/update/${data.id}`);
  };

  const handleAddNewRule = async () => {
    history.push("/paring/create");
  };
  const handleDelete = async (data) => {
    let token = localStorage.getItem("auth_token")?.toString();
    token = token?.substring(1, token.length - 1);

    await NetworkCall(
      `${config.api_url}pairing/rules/delete`,
      "POST",
      { rule_id: data?.id },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        setAlert({
          open: true,
          message: res?.data?.message ?? "",
          backgroundColor: " #4BB543",
        });
        isGetList();
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Something went wrong!",
          backgroundColor: "#FF3232",
        });
        console.log(err);
      });
  };

  const { addlist, listData } = state;

  return (
    <div className={classes.root}>
      <div className={classes.rootBody}>
        {!state.isopen && (
          <Grid container rowGap={3}>
            <Grid item xs={12}>
              <Subheader
                title="Paring Logic"
                count={listData?.length}
                search={search}
                setSearch={(e) => handleSearch(e.target.value)}
                onAddClick={handleAddNewRule}
              />
            </Grid>

            <Grid container item rowGap={1.25}>
              {listData?.length === 0 || !listData ? (
                <Grid item xs={12}>
                  <Typography
                    style={{ margin: "25vh 0px", textAlign: "center" }}
                  >
                    No Data
                  </Typography>
                </Grid>
              ) : (
                listData
                  .slice(page * pageSize - pageSize, page * pageSize)
                  .map((rule) => (
                    <Grid item xs={12}>
                      <ListItem
                        key={rule?.id}
                        item={{
                          name: rule?.rule_name,
                          createdAt: rule?.created_at,
                          updatedAt: rule?.last_modifyed,
                          vendorName: rule?.vendor?.first_name ?? "",
                        }}
                        handleEdit={() => ishandleEdit(rule)}
                        handleClone={() => ishandleOpen(rule)}
                        handleDelete={() => handleDelete(rule)}
                      />
                    </Grid>
                  ))
              )}
            </Grid>

            {listData?.length > 0 && (
              <Grid item xs={12}>
                <Stack
                  flexDirection="row"
                  justifyContent="flex-end"
                  style={{ marginTop: "-6px" }}
                >
                  <Pagination
                    count={Math.ceil(listData?.length / pageSize)}
                    page={page}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                    color="primary"
                  />
                </Stack>
              </Grid>
            )}
          </Grid>
        )}

        <DrawerComponent
          open={isView}
          anchor="right"
          handleClose={() => setIsview(!isView)}
          children={
            <>
              <div className={classes.drawertitle}>
                <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                  Conditions
                </Typography>
              </div>
              <div className={classes.drawer}>
                {!addlist?.length > 0 && (
                  <div
                    style={{
                      height: "64vh",
                      margin: "auto",
                      display: "flex",
                    }}
                  >
                    <Typography style={{ textAlign: "center", margin: "auto" }}>
                      <img src="../images/box@2x.png" alt="logo" />
                      <br />
                      No Conditions Added
                    </Typography>
                  </div>
                )}

                {addlist?.length > 0 && (
                  <div className={classes.addruleDrawerBody1}>
                    {addlist?.map((_item, _index) => {
                      return (
                        <div
                          className={classes.addruleDrawer}
                          style={{ marginBottom: 6 }}
                        >
                          <div style={{ height: "auto" }}>
                            <Card className={classes.card}>
                              <div className={classes.count}>{_index + 1}</div>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography className="title">
                                    Column Name{" "}
                                    <div className="body">
                                      {_item?.fact ?? ""}
                                    </div>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography className="title">
                                    Operator{" "}
                                    <div className="body">
                                      {_item?.operator ?? ""}
                                    </div>
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography
                                    className="title"
                                    style={{ marginTop: 6 }}
                                  >
                                    Value
                                    <div className="body">
                                      {_item?.operator === "in" ||
                                      _item?.operator === "not in" ? (
                                        <>
                                          {_item?.value &&
                                            _item?.value?.map((child, i) => {
                                              return i ===
                                                _item?.value?.length - 1
                                                ? child
                                                : child + ", ";
                                            })}
                                        </>
                                      ) : _item?.operator === "between" ? (
                                        <>{`${_item?.value?.from ?? ""}, ${
                                          _item?.value?.to ?? ""
                                        }`}</>
                                      ) : _item?.operator === "same value" ? (
                                        <>{"same value"}</>
                                      ) : (
                                        <>{_item?.value ?? ""}</>
                                      )}
                                    </div>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Card>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          }
        />
        <Snackbar
          open={alert.open}
          message={alert.message}
          ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAlert({ ...alert, open: false })}
          autoHideDuration={4000}
        />
      </div>
    </div>
  );
};
